exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-boom-bots-work-js": () => import("./../../../src/pages/how-boom-bots-work.js" /* webpackChunkName: "component---src-pages-how-boom-bots-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-tokenomics-js": () => import("./../../../src/pages/tokenomics.js" /* webpackChunkName: "component---src-pages-tokenomics-js" */),
  "component---src-pages-what-are-boom-bots-js": () => import("./../../../src/pages/what-are-boom-bots.js" /* webpackChunkName: "component---src-pages-what-are-boom-bots-js" */),
  "component---src-pages-what-is-boom-js": () => import("./../../../src/pages/what-is-boom.js" /* webpackChunkName: "component---src-pages-what-is-boom-js" */)
}

